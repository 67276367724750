import { Link, graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'

//import '../style/header.sass'
import 'typeface-open-sans'

const Header = ({ nav }) => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <header className={nav ? "color" : "nocolor" }>
            <nav className="navbar">
                <Link to="/" className="navbar-brand">
                    <Img
                        className="logo"
                        fluid={data.file.childImageSharp.fluid}
                        alt="Guardian of Life logotype"
                    />
                </Link>
                {nav && (
                    <>
                        <div className="menu-button">
                            <input type="checkbox" />
                            <div className="hamburger">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <p></p>

                            <div className="navigation-mobile">
                                <Link to="/">HOME</Link>
                                <Link to="/why">WHY</Link>
                                <Link to="/what-we-do">WHAT AND HOW</Link>
                                <Link to="/who-we-are">WHO</Link>
                                <a href="https://vimeo.com/349740181">VIDEO</a>
                            </div>
                        </div>
                        <div className="navigation">
                            <Link to="/">HOME</Link>
                            <Link to="/why">WHY</Link>
                            <Link to="/what-we-do">WHAT AND HOW</Link>
                            <Link to="/who-we-are">WHO</Link>
                        </div>
                    </>
                )}
            </nav>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header

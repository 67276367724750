import PropTypes from 'prop-types'
import React from 'react'

import SpeakerButton from './speaker-button'

class Video extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isToggleOn: true,
        }
        this.handleClick = this.handleClick.bind(this)
        this.videoRef = React.createRef()
    }

    handleClick() {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn,
        }))
        this.props.toggleDisplay()

        if(this.state.isToggleOn) {
            var videoElement = document.getElementById('trailer')

            if(videoElement != null) {
                videoElement.currentTime = 0
            }
        }
    }

    render() {
        return (
            <>
                <div className="video-wrapper">
                    <video
                        ref="videoRef"
                        autoPlay="autoplay"
                        muted={this.state.isToggleOn ? true : false}
                        loop
                        playsInline
                        id="trailer"
                        className={this.state.isToggleOn ? 'dark' : 'bright'}
                    >
                        <source
                            src={this.props.filePathWebm}
                            type="video/webm"
                        />
                        <source
                            src={this.props.filePathMP4}
                            type="video/mp4"
                        />
                    </video>
                </div>
                <SpeakerButton
                    toggleMute={this.handleClick}
                    muted={this.state.isToggleOn}
                />
            </>
        )
    }
}

Video.propTypes = {
    filePath: PropTypes.string,
}

export default Video

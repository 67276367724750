import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from './seo'
import Header from './header'
import Video from './video'
import Button from './button'

import '../style/landing.sass'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'typeface-open-sans'
import 'typeface-playfair-display'

class Landing extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isToggleOn: true,
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn,
        }))
    }

    render() {
        return (
            <div className="landing">
                <Header nav={false} />
                <SEO
                    title="Home"
                    description="What is it to embody the Guardian of Life? Caretaker of all lifeforms with compassion."
                />
                <Video
                    filePathWebm="vid/trailer-720.webm"
                    filePathMP4="vid/trailer-mp4-720.mp4"
                    toggleDisplay={this.handleClick}
                />
                <div className={ this.state.isToggleOn ? "container" : "container nodisplay" }>
                    <div className="text-display">
                        <div className="row justify-content-start">
                            <div className="col-md-10 offset-md-1 text-box">
                                <h3 className="landing-text-medium italic">
                                    What is it to embody the
                                </h3>
                            </div>
                        </div>
                        <div className="row justify-content-start">
                            <div className="col-md-10 offset-md-1 text-box">
                                <h1 className="landing-text-big">
                                    GUARDIAN OF LIFE
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="btn-display">
                        <div className="row btn-row justify-content-start">
                            <div className="col-md-4 btn-box">
                                <div className="flex-box-right">
                                    <Button
                                        label="WHY"
                                        url="/why"
                                        class_name="landing-btn"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 btn-box">
                                <div className="flex-box-center">
                                    <Button
                                        label="WHAT AND HOW"
                                        url="/what-we-do"
                                        class_name="landing-btn"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 btn-box">
                                <div className="flex-box-left">
                                    <Button
                                        label="WHO"
                                        url="/who-we-are"
                                        class_name="landing-btn"
                                    />
                                </div>
                            </div>
                            <a className="video-link" href="https://vimeo.com/349740181">&middot; VIEW VIDEO &middot;</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Landing

import PropTypes from 'prop-types'
import React from 'react'

class SpeakerButton extends React.Component {
    render() {
        return (
            <div>
                {
                    this.props.muted
                        ? null
                        : <span className="go-back" onClick={this.props.toggleMute}>HOME</span>
                }
                <img
                    className="speaker-button"
                    type="image/svg+xml"
                    onClick={this.props.toggleMute}
                    src={
                        this.props.muted
                            ? './icons/sound-off.svg'
                            : './icons/sound-on.svg'
                    }
                    alt="Toggle sound for video"
                />
            </div>
        )
    }
}

/*SpeakerButton.propTypes = {
  filePath: PropTypes.string
}*/

export default SpeakerButton
